
import React from 'react'
import Icon from 'react-icon-base'

const FaFileAlt = props => (
    <Icon viewBox="0 0 384 512" {...props}>
        <g><path d="M 224 136 V 0 H 24 C 10.7 0 0 10.7 0 24 v 464 c 0 13.3 10.7 24 24 24 h 336 c 13.3 0 24 -10.7 24 -24 V 160 H 248 c -13.2 0 -24 -10.8 -24 -24 Z m 64 236 c 0 6.6 -5.4 12 -12 12 H 108 c -6.6 0 -12 -5.4 -12 -12 v -8 c 0 -6.6 5.4 -12 12 -12 h 168 c 6.6 0 12 5.4 12 12 v 8 Z m 0 -64 c 0 6.6 -5.4 12 -12 12 H 108 c -6.6 0 -12 -5.4 -12 -12 v -8 c 0 -6.6 5.4 -12 12 -12 h 168 c 6.6 0 12 5.4 12 12 v 8 Z m 0 -72 v 8 c 0 6.6 -5.4 12 -12 12 H 108 c -6.6 0 -12 -5.4 -12 -12 v -8 c 0 -6.6 5.4 -12 12 -12 h 168 c 6.6 0 12 5.4 12 12 Z m 96 -114.1 v 6.1 H 256 V 0 h 6.1 c 6.4 0 12.5 2.5 17 7 l 97.9 98 c 4.5 4.5 7 10.6 7 16.9 Z"/></g>
    </Icon>
)

export default FaFileAlt
